import React from 'react';
import classes from './StoreItem.module.css';

const storeItem = ( props: any ) => {
    const distance = Math.floor(props.store.distance * 100) / 100;

    const handleBrokenImage = (event: any) => {
        let nativeEvent: any = event.nativeEvent;
        let target: any = nativeEvent.target;
        // console.log(`broken image:`, target);
        target.style.display = 'none';
    }

    return (
        <li className={classes.StoreItem}>
            <label>{props.store.name}</label><br/>
            <div className={classes.ImageContainer} onClick={() => props.clicked(props.store)}>
                <img
                    src={`data/stores/${props.store.placeId}/thumb.jpg`}
                    onError={(event) => handleBrokenImage(event)}
                /><br/>
            </div>
            <label>{`${distance} miles`}</label>
        </li>
    )
}

export default storeItem;
