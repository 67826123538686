import React, { Component } from 'react';

import Aux from '../../hoc/Aux/Aux';
import Product from '../../components/Product/Product';
// import About from '../../components/About/About';
import Stores from '../../components/Stores/Stores';
import classes from './Content.module.css';

export interface ContentProps { stores: any, location: any, clicked: any }
export interface ContentState {}

// <About />

class Content extends Component<ContentProps, ContentState> {
    constructor(props:ContentProps) {
        super(props);
        this.state = {};
    }

    render () {
        return (
            <Aux>
                <div className={classes.Content}>
                    <Product />
                    <Stores location={this.props.location} stores={this.props.stores} clicked={this.props.clicked}/>
                </div>
            </Aux>
        );
    }
}

export default Content;
