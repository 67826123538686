import React from 'react';
import classes from './Location.module.css';

const location = ( props: any ) => {
    return (
        <div className={classes.Location}>
            {props.location.latitude},{props.location.longitude}<br/>
            {props.location.zip}, {props.location.state}<br/>
        </div>
    )
}

export default location;
