import React, { Component } from 'react';
import Layout from './hoc/Layout/Layout';
import Content from './containers/Content/Content';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Model from './model/Model';

const fs = require('fs');
const Jimp = require('jimp');

export interface AppProps { model: Model }
export interface AppState {}

export default class App extends React.Component<AppProps, AppState> {

    handleStoreClicked(store: any): void {
        console.log(`handleStoreClicked: ${store.name}`);
        const mapsLink: string = `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${store.placeId}`;
        window.open(mapsLink, "_blank")
        // const proxyurl = "https://cors-anywhere.herokuapp.com/";
        // // let url = 'https://lh3.googleusercontent.com/p/AF1QipNXKavuwj5_sfcJ_hNfQc8aSbLBR3bWOj7hle6u=s1600-w480-h648';
        // // NOTE: REMOVED API KEY
        // let url = proxyurl + `https://maps.googleapis.com/maps/api/place/photo?photoreference=${store.photoReference}&maxheight=256&maxwidth=256&key=TBD`;
        // Jimp.read(url)
        //     .then((image: any) => {
        //         let newImage: any = image.cover(256, 256)
        //             .quality(60)
        //             .getBase64Async(Jimp.MIME_JPEG)
        //                 .then((base64data: string) => {
        //                     this.downloadBase64Image(base64data, store.placeId + '.jpg');
        //                 });
        //     })
        //     .catch((err: any) => {
        //         // Handle an exception.
        //         console.log(err);
        //     });
    }

    // data:image/png;base64
    downloadBase64Image(base64data: string, filename: string): void {
        var element = document.createElement('a');
        element.setAttribute('href', base64data);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    render () {
        // <Footer />
        return (
            <div>
                <Layout>

                    <Content stores={this.props.model.stores} location={this.props.model.location} clicked={(store:any) => this.handleStoreClicked(store)}/>
                </Layout>
            </div>
        );
   }
}
