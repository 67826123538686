import React from 'react';

import StoreItem from './StoreItem/StoreItem';
import Location from './Location/Location';
import classes from './Stores.module.css';

const stores = (props: any) => {
    let storeElements;
    if (props.stores) {
        storeElements = props.stores.map((store: any) => {
            return <StoreItem key={store.placeId} store={store} clicked={props.clicked}/>;
        });
    }

    return (
        <div className={classes.Stores}>
            <Location location={props.location} />
            {storeElements}
        </div>
    )
}

export default stores;
