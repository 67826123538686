import { EventEmitter } from "events";
const haversine = require('haversine');

const zipDb: any = require('../assets/data/zip-db.json');
const stores: any = require('../assets/data/stores-array.json');

export type Coords = {
    latitude: number;
    longitude: number;
}

export type Location = {
    latitude: number;
    longitude: number;
    zip: string;
    state: string;
}

export default class Model extends EventEmitter {

    // public stores: any[] = [];
    public location: Location = {
        latitude: 41.646096,
        longitude: -70.346473,
        zip: 'na',
        state: 'na'
    }

    constructor() {
        super();
        // console.log(data);
        this.getLocation()
            .then((location: Location) => {
                this.location = location;
                this.calculateDistance(stores, this.location);
                this.emit('ready');
            })
            .catch((error: any) => {
                console.log(error);
                this.location.zip = 'Ez';
                this.location.state = 'Es';
                this.emit('ready');
            });
    }

    get stores(): any[] {
        return stores;
    }

    calculateDistance(stores: any, currentLocation: Location): void {
        if (stores.length > 0) {
            stores.forEach((store: any) => {
                const loc: Location = {
                    latitude: store.location.LT,
                    longitude: store.location.LN,
                    zip: store.zip,
                    state: store.state
                }
                store.distance = this.getDistance(currentLocation, loc);
            });
            stores.sort((a: any, b: any) => {return a.distance - b.distance});
        }
    }

    getLocation(): Promise<Location> {
        return new Promise<Location>((resolve, reject) => {
            if ("geolocation" in navigator) {
                /* geolocation is available */
                console.log(`geolocation is available`);
                navigator.geolocation.getCurrentPosition((position: any) => {
                        console.log(position);
                        const zipAndState = this.getZipAndStateWithCoords(position.coords);
                        const location: Location = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            zip:  zipAndState.zip,
                            state: zipAndState.state
                        }
                        resolve(location);
                    },
                    (error: any) => {
                        // console.log(`gelocation: error:`, error);
                        reject(error)
                    });
            } else {
                // console.log(`geolocation is NOT available`);
                reject(`geolocation is NOT available`);
            }
        });
    }

    getZipAndStateWithCoords(coords: Coords): any {
        zipDb.forEach((zip: any) => {
            const end = {
                latitude: zip.LT,
                longitude: zip.LN
            }
            const distance = haversine(coords, end, {unit: 'mile'});
            zip.D = distance;
            // console.log(`zip distance:  ${zip.ST} ${zip.ZP} ${zip.D} ${zip.LT} ${zip.LN}`);
        });

        zipDb.sort((a: any, b: any) => {return a.D - b.D});
        // console.log(zipDb);
        console.log(`zipDb[0]: `, zipDb[0]);
        return {
            zip: zipDb[0].ZP,
            state: zipDb[0].ST
        }
    }

    getDistance(start: Location, end: Location): number {
        return haversine(start, end, {unit: 'mile'});
    }
}
